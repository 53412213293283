import React from "react";

export default function SearchBtn() {
  return (
    <div className="bg-white flex h-10 border border-neutral-400 rounded-lg items-center pr-2 justify-between focus-within:text-[#a9caff] transition duration-200">
      <input
        type="text"
        className="rounded-lg focus-visible:outline-none pl-2 w-full text-black"
      />
      <button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 drop-shadow-md"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </button>
    </div>
  );
}
