import React, { useState } from "react";
import CertificacionDepositos from "./RentaFijaViews/CertificacionDepositos";
import PapelComercial from "./RentaFijaViews/PapelComercial";
import { useCtx } from "../../contexts/data.context";
import BtnGreen from "../buttons/BtnGreen";
import { urlApi } from "../../variablesGlobales";
import axios from "axios";
export default function RentaFija() {
  const [viewRentaFija,setViewRentaFija]=useState(0)
  const [adminData, setAdminData] = useState(false);
  const [update, setUpdate]=useState(1);
  const [pageSavedState, setPageSavedState]=useState(false);
  const [loading, setLoading] = useState(true);
  const [calificadoras, setCalificadoras] = useState([]);
  const [emisores, setEmisores] = useState([]);


  const {certicacionDepositosData,papelComercialData,setSavedState}=useCtx()
  //ADD NEW ROW
  const addRow=()=>{
    viewRentaFija==1?certicacionDepositosData.push({
      id:update,
      titulo:"Certificacion Depositos",
      tipoMercado:"Primario"
    }):viewRentaFija==0&&papelComercialData.push({
      id:update,
      titulo:"Papel Comercial",
      tipoMercado:"Primario",
    })
    setUpdate(update+1)
  }
    //CREATE PAPEL COMERCIAL RENTA FIJA
    const postPapelComercial = () => {
      setLoading(true)
        console.log(papelComercialData)
        axios
        .post(`${urlApi}saveDataRentaFija`,papelComercialData,{
          headers: {
            "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
          }
        })
        .then((response) => {
          console.log(response.data);
          setPageSavedState(!pageSavedState)
          setSavedState({ open: true, successful: true })
        })
        .catch((error) => {
          console.log(error.request.responseText);
          setSavedState({ open: true, successful: false })
        });
    };
        //CREATE CERTIFICACION DEPOSITOS RENTA FIJA
        const postCertificacionDepositos = () => {
          console.log(certicacionDepositosData)
          axios
          .post(`${urlApi}saveDataRentaFija`,certicacionDepositosData,{
            headers: {
              "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
            }
          })
          .then((response) => {
            console.log(response.data);
            setPageSavedState(!pageSavedState)
            setSavedState({ open: true, successful: true })
          })
          .catch((error) => {
            console.log(error.request.responseText);
            setSavedState({ open: true, successful: false })
          });
      };
      const SaveChanges=()=>{
        viewRentaFija==0?postPapelComercial():viewRentaFija==1&&postCertificacionDepositos()
      }
      // GET CALIFICADORAS
      const getCalificadoras=()=>{
        axios
        .get(`${urlApi}getCalificadora`,{
          headers: {
            "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
          }
        })
        .then((response) => {
          console.log(response.data.data);
          setCalificadoras(response.data.data)
        })
        .catch((error) => {
          console.log(error.request.responseText);
        });
      }
      // GET EMISORES
      const getEmisores=()=>{
        axios
        .get(`${urlApi}getEmisor`,{
          headers: {
            "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
          }
        })
        .then((response) => {
          console.log(response.data.data);
          setEmisores(response.data.data)
        })
        .catch((error) => {
          console.log(error.request.responseText);
        });
      }
  React.useEffect(() => {},[update,papelComercialData]);
  React.useEffect(() => {
    getCalificadoras()
    setAdminData(JSON.parse(localStorage.getItem("adminData")))
    getEmisores()
    return ()=>{
      setCalificadoras([])
      setEmisores([])
      setAdminData({})
    }
  },[]);
  return (
    <div className="p-6 w-[85%] h-screen">
      <div className="max-h-[50%]">
      <img src="/imgs/logosilver.png" alt="logo silvercross" className="w-40"/>
      <div className="flex">
        <span className="text-[#326688]">
          Dramon <p className="mr-10 text-[#398c9b] font-semibold">Username</p>
        </span>
        <span className="text-[#326688]">
          1/10/2022
          <p className="mr-10 text-[#398c9b] font-semibold">Start Date</p>
        </span>
        <span className="text-[#326688]">
          1/2/2022<p className="mr-10 text-[#398c9b] font-semibold">End Date</p>
        </span>
      </div>
     <div className="flex justify-between">
     <div className="mt-6 font-semibold">
        <p>Renta Fija</p>
        <select
          name="producto"
          id="producto"
          className="text-[#398c9b] focus-visible:outline-none border-t-4 mt-2 font-semibold border-[#4aceab]"
          onChange={(e)=>setViewRentaFija(e.target.value)}
        >
          <option value={0}>Papel Comercial</option>
          <option value={1}>Certificacion Depositos</option>
        </select>
      </div>
     {adminData.rol===0||adminData.rol===1?<div className="flex items-center">
        <BtnGreen onClick={()=>addRow()} text="A&ntilde;adir Fila"/>
        <BtnGreen onClick={()=>SaveChanges()} text="Guardar Cambios"/>
      </div>:null}
     </div>
      </div>
      <div className="h-[55%] 2xl:h-[65%] overflow-auto">
     {viewRentaFija==0? <PapelComercial emisores={emisores} rol={adminData.rol} calificadoras={calificadoras} pageSavedState={pageSavedState} setPageSavedState={setPageSavedState} setLoading={setLoading} loading={loading} />:viewRentaFija==1&&
      <CertificacionDepositos emisores={emisores} rol={adminData.rol} calificadoras={calificadoras} pageSavedState={pageSavedState} setPageSavedState={setPageSavedState} setLoading={setLoading} loading={loading}/>}
      </div>
    </div>
  );
}
