import React, { useState } from "react";
import { useCtx } from "../../../contexts/data.context";
import axios from "axios";
import { urlApi } from "../../../variablesGlobales";
import AlertModal from "../../modals/AlertModal";
import Loading from "../Loading";
import BtnDelete from "../../buttons/BtnDelete";

export default function CertificacionDepositos({pageSavedState,rol,emisores,calificadoras,setPageSavedState,setLoading,loading}) {
  const [update, setUpdate] = useState(false);
  const [alertModalState, setAlertModalState] = useState({
    uid:"",
    stateConfirm:"",
    username:""
  });
    const {certicacionDepositosData,SetCerticacionDepositosData}=useCtx()
  const editar = (e, id) => {
    certicacionDepositosData.map((item,idx) => {
      if (idx == id) {
        item[e.target.name] = e.target.value;
        setUpdate(!update);
      }
      return item;
    });
  };
   //DELETE RENTA FIJA CERTIFICACION DEPOSITOS
   const deleteRentaFijaCD = () => {
    axios
    .post(`${urlApi}deleteRentaFija`,{uid:alertModalState.uid},{
      headers: {
        "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
      }
    })
    .then((response) => {
      console.log(response.data);
      setAlertModalState({
        uid:"",
        stateConfirm:"",
        username:""
      })
      setPageSavedState(!pageSavedState)
    })
    .catch((error) => {
      console.log(error.request.responseText);
    });
};
  //GET PAPEL COMERCIAL RENTA FIJA
  const getCertificacionDepositos = () => {
    setLoading(true)
    axios
      .post(`${urlApi}RentaFijaByTitle`,{
        titulo:"CERTIFICACION DEPOSITOS"
      },
      {
        headers: {
          "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
        }
      })
      .then((response) => {
        console.log(response.data.data);
       SetCerticacionDepositosData(response.data.data)
       setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    getCertificacionDepositos()
    return ()=>{
      SetCerticacionDepositosData([])
    }
  },[pageSavedState]);
  React.useEffect(() => {},[update]);
  return (
    <>
    {
      alertModalState.uid&&<AlertModal text={`¿Deseas eliminar ${alertModalState.username}?`} functionModal={deleteRentaFijaCD} setState={setAlertModalState} />
    }
   <div className="w-full h-full">
      <table>
      <thead className="sticky z-50 top-0 bg-white">
        <tr className="font-semibold">
          <td>
            <p className="w-44">Titulo</p>
          </td>
          <td>
            <p className="w-24">Emisor</p>
          </td>
          <td>
            <p className="w-28">Monto nominal</p>
          </td>
          <td>
            <p className="w-16">Cupon %</p>
          </td>
          <td>
            <p className="w-28">Tipo mercado</p>
          </td>
          <td>
            <p className="w-24">Plazo (Dias)</p>
          </td>
          <td>
            <p className="w-40">Fecha de emision</p>
          </td>
          <td>
            <p className="w-40">Fecha vencimiento</p>
          </td>
          <td>
            <p className="w-36">Pago de capital</p>
          </td>
          <td>
            <p className="w-36">Pago de interes</p>
          </td>
          <td>
            <p className="w-20">Calificacion</p>
          </td>
          <td>
            <p className="w-36">Calificadora</p>
          </td>
          <td>
            <p className="w-28">Rendimiento %</p>
          </td>
          <td>
            <p className="w-32">Comision casa %</p>
          </td>
          <td>
            <p className="w-32">Comision bolsa %</p>
          </td>
          <td>
            <p className="w-36">Comision casa MS</p>
          </td>
          <td>
            <p className="w-36">Comision bolsa MS</p>
          </td>
          {rol===0||rol===1?<td>
              <p className="w-16">Actions</p>
            </td>:null}
        </tr>
      </thead>
      <tbody>
        {loading?<div className="absolute w-[80vw]"><Loading/></div>:
          certicacionDepositosData.length>0?
        certicacionDepositosData.map((cDepositos,idx) => {
          return (
            <tr key={idx} className="border-b-2 border-[#398c9b]">
              <td><p className="w-full border">{cDepositos.titulo}</p></td>
              <td>
                <select
                  name="emisor"
                  id="emisor"
                  value={cDepositos.emisor}
                  onChange={(e) => editar(e, idx)}
                  className="focus-visible:outline-none w-full border bg-white"
                >
                  <option value="">Seleccionar</option>
                  {emisores.map((emisor,ide)=>{
               return(
                <option key={ide} value={emisor.nombre}>{emisor.nombre}</option>
               )
             })}
                </select>
              </td>
              <td>
                <input
                  onChange={(e) => editar(e, idx)}
                  value={cDepositos.montoEmitido}
                  name="montoEmitido"
                  type="number"
                  className="focus-visible:outline-none w-full text-center px-1 border"
                />
              </td>
              <td>
                <input
                  onChange={(e) => editar(e, idx)}
                  value={cDepositos.cupon}
                  name="cupon"
                  type="text"
                  className="focus-visible:outline-none w-full border text-center px-1"
                />
              </td>
              <td>
                <select
                  name="tipoMercado"
                  id="tipoMercado"
                  value={cDepositos.tipoMercado}
                  className="focus-visible:outline-none w-full border bg-white"
                  onChange={(e) => editar(e,idx)}
                >
                  <option value="Primario">Primario</option>
                  <option value="Secundario">Secundario</option>
                </select>
              </td>
              <td>
                <input
                  onChange={(e) => editar(e, idx)}
                  type="number"
                  value={cDepositos.plazoDias}
                  name="plazoDias"
                  className="focus-visible:outline-none w-full border text-center px-1"
                />
              </td>
              <td>
                <input
                  onChange={(e) => editar(e, idx)}
                  disabled={cDepositos.tipoMercado == "Primario" ? true : false}
                  type={cDepositos.tipoMercado == "Primario"? "text" : "date"}
                  value={cDepositos.tipoMercado=="Primario"?"":cDepositos.fechaEmision?cDepositos.fechaEmision.slice(0,10):""}
                  name="fechaEmision"
                  className="focus-visible:outline-none w-full border text-center px-1"
                />
              </td>
              <td>
                <input
                  onChange={(e) => editar(e, idx)}
                  disabled={cDepositos.tipoMercado == "Primario" ? true : false}
                  type={cDepositos.tipoMercado ==  "Primario" ? "text" : "date"}
                  value={cDepositos.tipoMercado=="Primario"?"":cDepositos.fechaVencimiento?cDepositos.fechaVencimiento.slice(0,10):""}
                  name="fechaVencimiento"
                  className="focus-visible:outline-none w-full border text-center px-1"
                />
              </td>
              <td><select
                  name="pagoCapital"
                  id="pagoCapital"
                  value={cDepositos.pagoCapital}
                  className="focus-visible:outline-none w-full border bg-white"
                  onChange={(e) => editar(e,idx)}
                >
                  <option value="">Seleccionar</option>
                  <option value="Trimestral">Trimestral</option>
                  <option value="Semestral">Semestral</option>
                </select></td>
              <td><select
                  name="pagoInteres"
                  id="pagoInteres"
                  value={cDepositos.pagoInteres}
                  className="focus-visible:outline-none w-full border bg-white"
                  onChange={(e) => editar(e,idx)}
                >
                  <option value="">Seleccionar</option>
                  <option value="Trimestral">Trimestral</option>
                  <option value="Semestral">Semestral</option>
                </select></td>
              <td><input
                  onChange={(e) => editar(e, idx)}
                  value={cDepositos.calificacion}
                  name="calificacion"
                  type="text"
                  className="focus-visible:outline-none w-full border text-center px-1"
                /></td>
              <td> <select
              name="calificadora"
              className="focus-visible:outline-none w-36 border bg-white"
              value={cDepositos.calificadora}
              onChange={(e)=>editar(e,idx)} >
                <option value="">Seleccionar</option>
              {calificadoras.map((calificadora,idc)=>{
               return(
                <option key={idc} value={calificadora.nombre}>{calificadora.nombre}</option>
               )
             })}
            </select></td>
              <td>
                <input
                  onChange={(e) => editar(e, idx)}
                  type="number"
                  value={cDepositos.rendimiento}
                  name="rendimiento"
                  className="focus-visible:outline-none w-full border text-center px-1"
                />
              </td>
              <td>
                <input
                  onChange={(e) => editar(e, idx)}
                  value={cDepositos.tipoMercado=="Secundario"?"":cDepositos.comisionCasa}
                  name="comisionCasa"
                  disabled={
                    cDepositos.tipoMercado == "Secundario" ? true : false
                  }
                  type="number"
                  className="focus-visible:outline-none w-full border text-center px-1"
                />
              </td>
              <td>
                <input
                  onChange={(e) => editar(e, idx)}
                  value={cDepositos.tipoMercado=="Secundario"?"":cDepositos.comisionBolsa}
                  name="comisionBolsa"
                  disabled={
                    cDepositos.tipoMercado == "Secundario" ? true : false
                  }
                  type="number"
                  className="focus-visible:outline-none w-full border text-center px-1"
                />
              </td>
              <td>
              <select
              disabled={
                cDepositos.tipoMercado == "Primario" ? true : false
              }
                    name="comisionCasaMS"
                    className={`focus-visible:outline-none border w-36 text-center ${ cDepositos.tipoMercado == "Primario" ? "bg-[#fafafa]" : "bg-white"}`}
                    value={cDepositos.tipoMercado=="Secundario"?cDepositos.comisionCasaMS:""}
                    onChange={(e) => editar(e, idx)}
                  >
                      <option value="">{cDepositos.tipoMercado==="Secundario"?"Seleccionar":""}</option>
                      <option value={4.00}> $4,00 </option>
                        <option value={9.00}> $9,00 </option>
                        <option value={18.00}> $18,00 </option>
                        <option value={35.00}> $35,00 </option>
                  </select>
              </td>
              <td>
              <select
               disabled={
                cDepositos.tipoMercado == "Primario" ? true : false
              }
                    name="comisionBolsaMS"
                    className={`focus-visible:outline-none border w-36 text-center ${ cDepositos.tipoMercado == "Primario" ? "bg-[#fafafa]" : "bg-white"}`}
                    value={cDepositos.tipoMercado=="Secundario"?cDepositos.comisionBolsaMS:""}
                    onChange={(e) => editar(e, idx)}
                  >
                      <option value="">{cDepositos.tipoMercado==="Secundario"?"Seleccionar":""}</option>
                      <option value={4.00}> $4,00 </option>
                        <option value={9.00}> $9,00 </option>
                        <option value={18.00}> $18,00 </option>
                        <option value={35.00}> $35,00 </option>
                  </select>
              </td>
             {rol===0||rol===1? <td>
                  <BtnDelete onClick={()=>setAlertModalState({
                    uid:cDepositos.uid,
                    stateConfirm:"",
                    username:cDepositos.emisor
                  })} />
                </td>:null}
            </tr>
          );
        }):<div className="absolute w-[80vw]"><p className="text-center mt-10 animate-pulse">No Hay datos para mostrar</p></div>}
      </tbody>
    </table>
   </div>
   </>
  );
}
