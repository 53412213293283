import React from 'react'
import { useCtx } from '../../contexts/data.context'

export default function StateSavedModal() {
    const {savedState,setSavedState}=useCtx()
    setTimeout(()=>{
        setSavedState({open:false,successful:true})

    },[5000])
    return (
      <>
       <div className={`${savedState.successful?"bg-[#4aceab]":"bg-red-500"} absolute w-[600px] p-6 rounded-t bottom-0 mx-auto right-0 left-0 text-center drop-shadow`} >
            <p className='w-full text-lg'>{savedState.successful?"Guardado con exito":"Error al guardar,  por favor intentalo nuevamente"}</p>
        </div>
      </>
    )
}
