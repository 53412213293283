import React, { useState } from "react";
import SearchBtn from "./SearchBtn";

export default function Panel({ propSetView, viewActive }) {
  const [dropList, setDropList] = useState(false);
  return (
    <div className="bg-[#a9caff] h-full min-w-[15%] p-1 border-r border-neutral-500">
      <SearchBtn />
      <div className="flex flex-col mt-10">
        <button
          onClick={() => propSetView(0)}
          className={`${
            viewActive === 0 && "bg-blue-200 font-bold"
          } rounded my-2 h-14 flex items-center px-2`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
          Customer
        </button>
        <button
          onClick={() => setDropList(!dropList)}
          className={"my-2 h-14 flex items-center px-2"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          Products
        </button>
        {
          /*** sub menu */
          dropList && (
            <>
              <button
                onClick={() => propSetView(1)}
                className={`${
                  viewActive === 1 && "bg-blue-200 font-bold"
                } my-2 rounded h-8 flex items-center px-2 ml-6 justify-left`}
              >
                Renta fija
              </button>
              <button
                onClick={() => propSetView(2)}
                className={`${
                  viewActive === 2 && "bg-blue-200 font-bold"
                } my-2 rounded h-8 flex items-center px-2 ml-6 justify-left`}
              >
                Renta variable
              </button>
            </>
          )
        }
        <button
          onClick={() => propSetView(4)}
          className={`${
            viewActive === 4 && "bg-blue-200 font-bold"
          } my-2 rounded h-14 flex items-center px-2`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
            />
          </svg>
          Parametros
        </button>
        <button
          onClick={() => propSetView(3)}
          className={`${
            viewActive === 3 && "bg-blue-200 font-bold"
          } my-2 rounded h-14 flex items-center px-2`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
            />
          </svg>
          Admin
        </button>
      </div>
    </div>
  );
}
