import React from "react";
import SearchBtn from "../SearchBtn";
import {urlApi} from "../../variablesGlobales"
import axios from "axios";
import Loading from "../views/Loading"
import { useNavigate} from 'react-router-dom'
import DocumentsModal from "../modals/DocumentsModal";

export default function Customer() {
  const [customersData,setCustomerData]=React.useState([])
  const [update,setUpdate]=React.useState(false)
  const [loading,setLoading]=React.useState(true)
  const [documentModalState,setDocumentModalState]=React.useState(false)
  const [customerToLookUp,setCuromerToLookUp]=React.useState({})


  const history=useNavigate();
/// GET CUSTOMERS DATA FROM DB
  const getUsers=(token)=>{
    setLoading(true)
    axios.get(`${urlApi}customers`,{
      headers: {
        "auth-token": token, //the token is a variable which holds the token
      }
    })
    .then((response)=>{
      console.log(response)
      setCustomerData(response.data.data)
      setLoading(false)
    })
    .catch((error)=>{
      console.log(error.response.data.ok)
      const validToken=error.response.data.ok
     if(validToken===false){
        localStorage.removeItem("accessKey")
        localStorage.removeItem("adminData")
        history("/")
      }
    })
  }
  // SELECT CUSTOMER
  const selectCustomers=(name,lastname)=>{
    setCuromerToLookUp({name,lastname})
  }
  React.useEffect(()=>{
    const token=localStorage.getItem("accessKey")
    getUsers(token)
    return()=>{
      setCustomerData([])
    }
  },[])
  React.useEffect(()=>{
  },[update])
  return (
    <div>
      {documentModalState&&<DocumentsModal setDocumentModalState={setDocumentModalState} />}
     <div className="h-[25vh]">
          {/*** search bar ***/}
      <div className="bg-[#222222] flex items-center h-16 px-4">
        <span className="text-white mr-4">Customer</span>
        <SearchBtn />
      </div>
      {/*** perfil header ***/}
      <div className="flex py-1 px-4">
        <img
          src="/imgs/usuario.png"
          alt="user picture"
          className="rounded-full mr-6"
        />
        <div>
          <p className="text-[#a9caff]">Deals</p>
          <p>2</p>
          <p>{customerToLookUp.name+" "+customerToLookUp.lastname}</p>
        </div>
      </div>
     </div>
      {/***data table ***/}
      <div className="bg-[#ffa9a915] p-4 h-screen">
        <p className="text-[#a9caff]">Data</p>
        <table className="w-full">
          <thead className="border-y border-red-400">
            <tr>
              <th className="w-40">Cedula Identidad</th>
              <th className="w-36">Nombres</th>
              <th className="w-36">Apellidos</th>
              <th className="w-36">Celular</th>
              <th className="w-48">Correo Electronico</th>
              <th className="w-36">Estado civil</th>
              <th className="w-32">Fecha Nacimiento</th>
              <th className="w-36">Direccion</th>
              <th className="w-20">Archivos</th>
            </tr>
          </thead>
          <tbody>
            {loading?<div className="absolute w-[80vw]"><Loading/></div>:
              customersData.length>0?
              customersData.map((customer,idx)=>{
                return(
                  <tr onClick={()=>selectCustomers(customer.nombres,customer.apellidos)} key={idx} className=" cursor-pointer hover:bg-red-100 ">
                    <td className="z-10"><p name="identidad" className="border text-center w-full">{customer.identidad}</p></td>
                    <td><p name="nombres" className="border text-center w-full">{customer.nombres}</p ></td>
                    <td><p name="apellidos" className="border text-center w-full truncate ">{customer.apellidos}</p></td>
                    <td><p name="celular" className="border text-center w-full">{customer.celular}</p></td>
                    <td><p name="email" className="border text-center w-full">{customer.email}</p></td>
                    <td><p name="estado_civil" className="border text-center w-full">{customer.estado_civil}</p></td>
                    <td><p name="fecha_nacimiento" className="border text-center w-full">{customer.fecha_nacimiento.slice(0,10)}</p></td>
                    <td><p name="direccion" className="border text-center w-full">{customer.direccion}</p></td>
                    <td> <button onClick={()=>setDocumentModalState(true)} className="hover:text-gray-700 hover:bg-red-300 border w-full h-full flex justify-center py-[2px]" ><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z" clipRule="evenodd" />
                    <path d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z" />
                  </svg></button> </td>
                </tr>
                )
              }):<div className="absolute w-[80vw]"><p className="text-center mt-10 animate-pulse">No Hay datos para mostrar</p></div> 
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}
