import { createContext, useState, useContext } from "react";

const Context = createContext("");
export const useCtx = () => useContext(Context);
export const Provider = ({ children }) => {
  const calificadoras=[];
  const [certicacionDepositosData, SetCerticacionDepositosData] = useState([]);
  const [papelComercialData, setPapelComercialData]=useState([]);
  // state to StateSavedModal
  const [savedState,setSavedState]=useState({
    open:false,
    successful:undefined
  });
  const [rvAccionesData, setRvAccionesData] = useState([]);
  const [rvCertificadosAprobacionData, setRvCertificadosAprobacionData] = useState([]);
  const [emisores, setemisores] = useState([]);

  return (
    //@ts-ignore
    <Context.Provider value={{certicacionDepositosData,setRvCertificadosAprobacionData,rvCertificadosAprobacionData,savedState,setSavedState,rvAccionesData,setemisores,setRvAccionesData,SetCerticacionDepositosData,papelComercialData,setPapelComercialData,calificadoras,emisores}}>
      {children}
    </Context.Provider>
  );
};