import React, { useState, useEffect } from "react";
import SearchBtn from "../SearchBtn";
import BtnGreen from "../buttons/BtnGreen";
import ModalAddUser from "../ModalAddUser";
import axios from "axios";
import AlertModal from "../modals/AlertModal";
import { urlApi } from "../../variablesGlobales";
import Loading from "./Loading";

export default function Admin() {
  // STATES
  const [userData, setUserData] = useState([]); //here is users data
  const [adminData, setAdminData] = useState({}); //here is users data
  const [tt, setTt] = useState(false); //here is handle state page
  const [addUserForm, setAddUserForm] = useState(false); //here is handle state view of modaluser
  const [alertFormData, setAlertForm] = useState({
    uid: "",
    stateConfirm: false,
    username: "",
  }); //here is handle state view of alertModal
  // GET USERS FUNCTION
  const getUsers = () => {
    axios
      .get(`${urlApi}users`,{
        headers: {
          "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
        }
      })
      .then((response) => {
        console.log(response.data.data);
        setUserData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // DELETE USER FUNCTION
  const deleteUser = () => {
    axios
      .delete(`${urlApi}deleteUser/${alertFormData.uid}`, {
        headers: {
          "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
        }
      })
      .then((response) => {
        console.log(response);
        setAlertForm({
          uid: "",
          stateConfirm: !alertFormData.stateConfirm,
          username: "",
        });
      })
      .catch((error) => {
        console.log(error.request.responseText);
        console.log( alertFormData.uid);
      });
  };
  // UPDATE ROL FUNCTION
  const updateRol = (uid, rol,idx) => {
    userData[idx]["rol"]=parseInt(rol)
    axios
      .put(`${urlApi}rolUSer`, {
        uid: uid,
        rol: rol,
      },{
        headers: {
          "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
        }
      })
      .then((response) => {
        console.log(response);
        setTt(!tt);
      })
      .catch((error) => {
        console.log(error.response.data.status);
      });
  };
  //USE EFFECT
  useEffect(() => {
    getUsers();
    setAdminData(JSON.parse(localStorage.getItem("adminData")))
    return () => {
      setUserData([]);
      setAdminData({})
    };
  }, [tt,alertFormData.stateConfirm]);

  return (
    <div>
      {addUserForm && (
        <ModalAddUser setAddUserForm={setAddUserForm} setTt={setTt} />
      )}
      {alertFormData.uid && (
        <AlertModal
          text={`¿Deseas eliminar al usuario ${alertFormData.username}?`}
          setState={setAlertForm}
          data={alertFormData}
          functionModal={deleteUser}
        />
      )}
      <div className="bg-[#222222] flex items-center h-16 px-4">
        <span className="text-white mr-4">Admin</span>
        <SearchBtn />
      </div>
      <div className="p-6 flex items-center">
        <img
          src="/imgs/usuario.png"
          alt="user picture"
          className="rounded-full mr-10"
        />
        <div className="flex justify-between w-full">
          <div>
            <p className="text-[#a9caff]">{adminData.rol==0?"Admin":adminData.rol==1?"Update":adminData.rol==2&&"Read"}</p>
            <span>{adminData.nombre}</span>
          </div>
          <div className="flex items-end">
           {adminData.rol===0&&<BtnGreen
              text="Añadir usuario"
              onClick={() => setAddUserForm(!addUserForm)}
            />}
          </div>
        </div>
      </div>
      {/***data table ***/}
      <div className="bg-[#ffa9a915] p-4 h-screen">
        <table className="w-full">
          <thead className="border-y border-red-400">
            <tr>
              <th>Username</th>
              <th>Nombres</th>
              <th>Role For</th>
              <th>Email</th>
              <th>Last Seen</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userData.length>0?
              userData.map((user,idx) => {
                return (
                  <tr key={user.uid}>
                    <td>{user.username}</td>
                    <td>{user.nombre}</td>
                    <td>
                      <select
                        name="roleFor"
                        id="roleFor"
                        disabled={adminData.rol===0?false:true}
                        className="focus-visible:outline-none bg-transparent"
                        value={user.rol}
                        onChange={(e) => updateRol(user.uid, e.target.value,idx)}
                      >
                        <option value={2}>Read</option>
                        <option value={1}>Update</option>
                        <option value={0}>Admin</option>
                      </select>
                    </td>
                    <td>{user.email}</td>
                    <td>{user.dateSession}</td>
                    <td className="flex items-center justify-center">
                      <input type="checkbox" />
                      {adminData.rol===0?<button
                        className="ml-2"
                        onClick={() =>
                          setAlertForm({
                            ...alertFormData,
                            uid: user.uid,
                            username: user.username,
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>:false}
                    </td>
                  </tr>
                );
              }):<div className="absolute w-[80vw]"><Loading/></div> }
          </tbody>
        </table>
      </div>
    </div>
  );
}
