import React, { useState } from "react";
import InputForm from "./Inputs/InputForm";
import axios from "axios";
import BtnGreen from "../components/buttons/BtnGreen";
import Loading from "./views/Loading";
import { urlApi } from "../variablesGlobales";

export default function ModalAddUser({ setAddUserForm,setTt }) {
  const [addUserData, setAddUserData] = useState({});
  const [signupOk, setSignupOk] = useState(false);
  const [losadingState, setLoadingState] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  // SIGNUP FUNCTION
  const signup = (e) => {
    e.preventDefault();
    setLoadingState(true)
    axios
      .post(`${urlApi}signup`,addUserData,{
        headers: {
          "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
        }
      })
      .then((response) => {
        console.log(response);
        setSignupOk(true);
      })
      .catch((error) => {
        console.log(JSON.parse(error.request.responseText).message);
        setErrorStatus(JSON.parse(error.request.responseText).message);
        setLoadingState(false)
      });
  };
  /// HANDLER INPUTS FORM
  const handlerInputs = (e) => {
    setAddUserData({ ...addUserData, [e.target.name]: e.target.value });
    console.log(addUserData);
  };
  return (
    <div className="fixed bg-black w-full h-full bg-opacity-30 flex justify-center items-center top-0 left-0">
      <div className="border p-6 rounded bg-white w-96">
        {!signupOk ? (
          <>
            <div className="flex justify-end">
              <button
                onClick={() => setAddUserForm(false)}
                className="text-3xl bg-gray-200 rounded-full px-2 absolute -mt-5 -mr-4"
              >
                X
              </button>
            </div>
            <h2 className="text-4xl mb-2 text-center">Nuevo usuario</h2>
            <form onSubmit={(e) => signup(e)} className="flex flex-col">
              <InputForm
                text="Usuario"
                type="text"
                name="username"
                onChange={(e) => handlerInputs(e)}
              />
              <InputForm
                text="Contraseña"
                type="password"
                name="password"
                onChange={(e) => handlerInputs(e)}
              />
              <InputForm
                text="Nombres"
                type="text"
                name="nombre"
                onChange={(e) => handlerInputs(e)}
              />
              <InputForm
                text="Correo electronico"
                type="email"
                name="email"
                onChange={(e) => handlerInputs(e)}
              />
              {errorStatus && (
                <p className="border-red-600 border text-red-600 rounded mt-2 py-2 text-center">
                  {errorStatus}
                </p>
              )}
               {losadingState&&<Loading/>}
              <div className="mt-4 flex justify-center">
                <button
                  type="submit"
                  className="bg-[#4aceab] hover:bg-[#399e83] transition duration-200 w-full rounded py-2"
                >
                  Registrar
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="text-center">
            <p className="mb-6 text-xl">Usuario registrado</p>
            <BtnGreen onClick={() => {setAddUserForm(false);setTt(true)}} text="Ok" />
          </div>
        )}
      </div>
    </div>
  );
}
