import React from "react";

export default function InputForm({ text, type,onChange,name }) {
  return (
    <>
      <label htmlFor={text}>{text}</label>
      <input
        onChange={onChange}
        type={type}
        className="border rounded py-2 focus-visible:outline-none px-2"
        name={name}
        required
      />
    </>
  );
}
