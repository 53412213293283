import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import InputLogin from '../components/InputLogin'
import { useNavigate} from 'react-router-dom'
import axios from 'axios'
import { urlApi } from '../variablesGlobales'
import LoadingBounce from '../components/views/LoadingBounce'

export default function Login() {
    // states
    const [loginData,setLoginData]=useState({})
    const [loading,setLoading]=useState(false)
    const [errorLogin,setErrorLogin]=useState(false)

    // hook's router
    const history=useNavigate();
    // LOGIN FUNCTION
  const handleLogin=(e)=>{
    e.preventDefault()
    setLoading(true)
    axios.post(`${urlApi}login`,loginData)
    .then((response)=>{
      console.log(response)
      localStorage.setItem("accessKey",response.data.token)
      localStorage.setItem("adminData",JSON.stringify(response.data.usuario))
      history("/admin")
    })
    .catch((error)=>{
      console.log(error)
      setErrorLogin(error.response.data.err.message)
      setLoading(false)
    })
  }
   /// HANDLER INPUTS FORM
   const handlerInputs=(e)=>{
    setLoginData({...loginData,[e.target.name]:e.target.value})
    console.log(loginData)
  }
    return (
        <div className='font-[Switzer]'>
            <img src="/imgs/logosilver.png" alt="logo silvercross" className='mx-auto w-60' />
           <div className='border-2 rounded max-w-[1000px] mx-auto p-10'>
               <h1 className='font-bold text-3xl mb-6'>SilverCross Administration Login</h1>
               <div className='flex'>
                   <div className='w-4/12 pt-4 flex flex-col justify-between pr-4'>
                       <p>Use a valid username and password to access the admin backend</p>
                       <Link to="/" className='font-bold text-lg'>Go to site home page</Link>
                      <span className='mx-auto'> <img src="/imgs/candado.png" /></span>
                   </div>
                   <div className='w-8/12 px-10'>
                       <div className='bg-[#e7e6e6] flex items-center justify-center h-80 rounded border border-neutral-300'>
                          <form onSubmit={(e)=>handleLogin(e)}>
                          <InputLogin label="User Name" type="text" name="username" onChange={(e)=>handlerInputs(e)}/>
                          <InputLogin label="Password" type="password" name="password" onChange={(e)=>handlerInputs(e)}/>
                          {
                            errorLogin&&
                            <div className='border border-red-600 rounded p-3 text-red-600 bg-white'>
                              <p>{errorLogin} </p>
                            </div>
                          }
                         <div className='flex justify-end pr-16 mt-10'>
                         <button type='submit' className='bg-black rounded text-white w-40 py-2 hover:bg-black/80 transition duration-200 flex justify-center items-center'><span className='mr-2'>Log In</span> {loading&&<LoadingBounce/>} </button>
                         </div>
                          </form>
                       </div>
                   </div>
               </div>
           </div>
        </div>
    )
}
