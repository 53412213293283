import React from 'react'
import CardParametros from '../CardParametros'
import CardParametrosWithImg from '../CardParametrosWithImg'
import axios from 'axios'
import { urlApi } from '../../variablesGlobales'

export default function Parametros() {
  const [update,setUpdate]=React.useState(false)
  const [calificadoras,setCalificadora]=React.useState([])
  const [emisores,setEmisores]=React.useState([])
  const [loadingEmisores,setLoadingEmisores] = React.useState(true);
  const [loadingCalificadora,setLoadingCalificadora] = React.useState(true);
  const [emisoresSaved, setEmisoresSaved] = React.useState(false);
  const [calificadoraSaved, setCalificadoraSaved] = React.useState(false);

  // RANDOM NUMBER
  function numeroAleatorioDecimales(min=0,max=10000) {
    var num = Math.random() * (max - min);
    return num + min;
  }
  // GET DATA FROM DB
  const getEmisores=()=>{
    setLoadingEmisores(true)
    axios.get(`${urlApi}getEmisor`,{
      headers: {
        "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
      }
    })
    .then((response)=>{
      console.log(response.data.data)
      let data=response.data.data
      data.map((item) => {
        item["disabled"] = true;
        item["id"] = numeroAleatorioDecimales();
        setEmisores(data)
        return item
        })
      setLoadingEmisores(false)
    })
    .catch((error)=>{
      console.log(error)
    })
  }
    // GET DATA FROM DB
    const getCalificadoras=()=>{
      setLoadingCalificadora(true)
      axios.get(`${urlApi}getCalificadora`,{
        headers: {
          "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
        },
      })
      .then((response)=>{
        console.log(response.data.data)
        let data=response.data.data
        data.map((item) => {
          item["disabled"] = true;
          item["id"] = numeroAleatorioDecimales();
          setCalificadora(data)
          return item
          })
        setLoadingCalificadora(false)
      })
      .catch((error)=>{
        console.log(error)
      })
    }
  //USE EFFECTS
  React.useEffect(()=>{
    getCalificadoras()
    return ()=>{
      setCalificadora([])
    }
  },[calificadoraSaved])
  React.useEffect(()=>{
    getEmisores()
    return ()=>{
      setEmisores([])
    }
  },[emisoresSaved])
  React.useEffect(()=>{
  },[update])

    return (
        <div className='p-6 flex'>
          <CardParametrosWithImg emisoresSaved={emisoresSaved} setEmisoresSaved={setEmisoresSaved} onClick={()=>{emisores.unshift({id:numeroAleatorioDecimales(),path:"",disabled:false,nombre:""});setUpdate(!update)}} setUpdate={setUpdate} update={update} data={emisores} title="Emisores" textBtnAdd={"emisor"} placeholder="Escriba el nuevo Emisor" loadingEmisores={loadingEmisores}setLoadingEmisores={setLoadingEmisores} />
          <CardParametros calificadoraSaved={calificadoraSaved} setCalificadoraSaved={setCalificadoraSaved} loadingCalificadora={loadingCalificadora} onClick={()=>{calificadoras.unshift({id:numeroAleatorioDecimales(),disabled:false,nombre:""});setUpdate(!update)}} setUpdate={setUpdate} update={update} data={calificadoras} setLoadingCalificadora={setLoadingCalificadora}  title="Calificadoras" textBtnAdd={"calificadora"}placeholder="Escriba la nueva Calificadora"/>
        </div>
    )
}
