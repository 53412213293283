import React, { useState, useEffect } from "react";
import BtnGreen from "../buttons/BtnGreen";
import { useCtx } from "../../contexts/data.context";
import Acciones from "./RentaVariableViews/Acciones";
import { urlApi } from "../../variablesGlobales";
import axios from "axios";
import CertificadosAprobacion from "./RentaVariableViews/CertificadosAprobacion";

export default function RentaVariable() {
  const [update, setUpdate] = useState(0);
  const [viewRentaVariable, setViewRentaVariable] = useState(0);
  const [adminData, setAdminData] = useState({});
  const [loading, setLoading] = useState(true);
  const [emisores, setEmisores] = useState([]);
  const [pageSavedState, setPageSavedState] = useState(false);
  const { rvAccionesData, setSavedState, rvCertificadosAprobacionData } =
    useCtx();
  //ADD NEW ROW
  const addRow = () => {
    viewRentaVariable == 0
      ? rvAccionesData.push({
          id: update,
          titulo: "Acciones",
          tipoMercado: "Primario",
        })
      : viewRentaVariable == 1 &&
        rvCertificadosAprobacionData.push({
          id: update,
          titulo: "Certificados de aprobacion",
          tipoMercado: "Primario",
        });
    setUpdate(update + 1);
  };
  //CREATE ACCION RENTA VARIABLE
  const postAccionesRentaVariable = () => {
    setLoading(true);
    axios
      .post(`${urlApi}saveDataRentaVar`, rvAccionesData, {
        headers: {
          "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
        },
      })
      .then((response) => {
        console.log(response.data);
        setSavedState({ open: true, successful: true });
        setPageSavedState(!pageSavedState);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.request.responseText);
        setSavedState({ open: true, successful: false });
      });
  };
  //CREATE CERTIFICADOS DE APROBACION RENTA VARIABLE
  const postCertificadosAprobacion = () => {
    setLoading(true);
    axios
      .post(`${urlApi}saveDataRentaVar`, rvCertificadosAprobacionData, {
        headers: {
          "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
        },
      })
      .then((response) => {
        console.log(response.data);
        setSavedState({ open: true, successful: true });
        setPageSavedState(!pageSavedState);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.request.responseText);
        setSavedState({ open: true, successful: false });
      });
  };
  // GET EMISORES
  const getEmisores = () => {
    axios
      .get(`${urlApi}getEmisor`, {
        headers: {
          "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setEmisores(response.data.data);
      })
      .catch((error) => {
        console.log(error.request.responseText);
      });
  };
  // save changes button
  const SaveChanges = () => {
    viewRentaVariable == 0
      ? postAccionesRentaVariable()
      : viewRentaVariable == 1 && postCertificadosAprobacion();
  };
  useEffect(() => {
    setAdminData(JSON.parse(localStorage.getItem("adminData")))
    getEmisores();
    return () => {
      setEmisores([]);
      setAdminData({})
    };
  }, []);
  return (
    <div className="p-6">
      <img src="/imgs/logosilver.png" alt="logo silvercross" className="w-40" />
      <div className="flex">
        <span className="text-[#326688]">
          Dramon <p className="mr-10 text-[#398c9b] font-semibold">Username</p>
        </span>
        <span className="text-[#326688]">
          1/10/2022
          <p className="mr-10 text-[#398c9b] font-semibold">Start Date</p>
        </span>
        <span className="text-[#326688]">
          1/2/2022<p className="mr-10 text-[#398c9b] font-semibold">End Date</p>
        </span>
      </div>
      <div className="flex justify-between items-center">
        <div className="mt-6 font-semibold">
          <p>Renta Variable</p>
          <select
            name="producto"
            id="producto"
            className="text-[#398c9b] focus-visible:outline-none border-t-4 mt-2 font-semibold border-[#4aceab]"
            onChange={(e) => setViewRentaVariable(e.target.value)}
          >
            <option value={0}>Acciones</option>
            <option value={1}>Certificados de aprobacion</option>
          </select>
        </div>
        {adminData.rol===0||adminData.rol===1?<div>
          <BtnGreen onClick={() => addRow()} text="A&ntilde;adir Fila" />
          <BtnGreen onClick={() => SaveChanges()} text="Guardar Cambios" />
        </div>:null}
      </div>
      {viewRentaVariable == 0 ? (
        <Acciones
          pageSavedState={pageSavedState}
          setPageSavedState={setPageSavedState}
          emisores={emisores}
          setLoading={setLoading}
          loading={loading}
          rol={adminData.rol}
        />
      ) : (
        viewRentaVariable == 1 && (
          <CertificadosAprobacion
            pageSavedState={pageSavedState}
            setPageSavedState={setPageSavedState}
            emisores={emisores}
            setLoading={setLoading}
            loading={loading}
            rol={adminData.rol}
          />
        )
      )}
    </div>
  );
}
