import React from 'react'

export default function InputLogin({label,type,onChange,name}) {
    return (
        <div className='flex justify-between w-96 mb-6 items-center mx-auto'>
            <label htmlFor="username" className='w-40 font-bold'>{label}</label>
            <input onChange={onChange} type={type} name={name} required className='rounded w-[350px] px-2 py-1 border focus-visible:outline-none'/>
        </div>
    )
}
