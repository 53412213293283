import React from "react";
import Panel from "../components/Panel";
import Customer from "../components/views/Customer";
import RentaFija from "../components/views/RentaFija";
import RentaVariable from "../components/views/RentaVariable";
import Administrar from "../components/views/Admintrar";
import Parametros from "../components/views/Parametros";
import { useNavigate } from "react-router-dom";
import StateSavedModal from "../components/modals/StateSavedModal";
import { useCtx } from "../contexts/data.context";

export default function Admin() {
  const [view,setView] = React.useState(0);
  const [token,setToken] = React.useState("");
  const history=useNavigate()
  const {savedState}=useCtx()
  React.useEffect(()=>{
    setToken(localStorage.getItem("accessKey"))
  },[])
  // LOGOUT
  const logout=()=>{
    localStorage.removeItem("accessKey")
    localStorage.removeItem("adminData")
    history("/")  
  }
  return (
   <>
   {
     token&& <div className="h-screen overflow-hidden font-[Switzer] relative">
     <div className="bg-[#4aceab] absolute w-full px-4 h-16 flex items-center text-white justify-between">
       <span className="font-medium text-xl drop-shadow">AdminSIL</span>
       <button onClick={()=>logout()} className="flex hover:text-[#f5f3f3] drop-shadow">Cerrar sesion <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
</svg></button>
     </div>
     <div className="h-full pt-16 flex">
       <Panel propSetView={setView} viewActive={view} />
       <div className="w-full">
         {view === 0 ? (
           <Customer />
         ) : view === 1 ? (
           <RentaFija/>
         ) : view === 2 ? (
           <RentaVariable />
         ) : view === 3 ? (
           <Administrar />
         ) : (
           view === 4 && <Parametros />
         )}
       </div>
       {savedState.open&&<StateSavedModal/>
}
     </div>
   </div>
   }
   </>
  );
}
