import React from "react";
import BtnGreen from "./buttons/BtnGreen";
import axios from "axios";
import { urlApi } from "../variablesGlobales";
import Loading from "./views/Loading";
import ButtonEdit from "./buttons/ButtonEdit";
import ButttonDelete from "./buttons/BtnDelete";
import AlertModal from "./modals/AlertModal";
import { useCtx } from "../contexts/data.context";

export default function CardParametros({
  title,
  textBtnAdd,
  data,
  onClick,
  calificadoraSaved,
  setUpdate,
  setCalificadoraSaved,
  update,
  placeholder,
  loadingCalificadora,
  setLoadingCalificadora,
}) {
  //states
  const [adminData, setAdminData] = React.useState({});
  const [disabled, setDisabled] = React.useState(false);
  const [alertModalState, setAlertModalState] = React.useState({
    uid: "",
    stateConfirm: "",
    username: "",
  });
  // CONTEXT DATA
  const { setSavedState } = useCtx();
  //EDIT FIELDS
  const editParametro = (e, idx) => {
    data[idx][e.target.name] = e.target.value;
    if(!data[idx]["id"]){
      data[idx]["id"]=data[idx].uid
    }
    setUpdate(!update);
    console.log(data);
  };
  // UPDATE CALIFICADORA
  const updateCalificadora = () => {
    data.map((calificadora) => {
      let count1=0
      let count2=0
      if (calificadora.id) {
        setLoadingCalificadora(true);
        count1++
        axios
          .post(`${urlApi}saveDataCalificadora`, calificadora,{
            headers: {
              "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
            }
          })
          .then((response) => {
            console.log(response);
            count2++
            if (count1 ==count2) {
              setCalificadoraSaved(!calificadoraSaved);
              setSavedState({ open: true, successful: true });
            }
          })
          .catch((error) => {
            console.log(error);
            setSavedState({ open: true, successful: false });
          });
      }
    });
  };
  // DELETE CALIFICADORA
  const buttonDeleteClick=(uid,nombre,idx)=>{
    uid?setAlertModalState({
      uid:uid,
      stateConfirm: "",
      username:nombre,
    }):data.splice(idx,1);setUpdate(!update);
  }
  // DELETE CALIFICADORA
  const deleteCalificadora = () => {
    axios
      .post(`${urlApi}deleteCalificadora`, {
        id: alertModalState.uid
      },{
        headers: {
          "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
        }
      })
      .then((response) => {
        console.log(response);
        setAlertModalState({
          uid: "",
          stateConfirm: "",
          username: "",
        });
        setCalificadoraSaved(!calificadoraSaved);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  // EDIT BUTTON HANDLER
  const handleClickEdit = (idx) => {
    data[idx]["disabled"] = !data[idx]["disabled"];
    setDisabled(!disabled);
  };

  React.useEffect(()=>{
    setAdminData(JSON.parse(localStorage.getItem("adminData")))
    data.length>0&&data.map((item) => {
      item["disabled"] = true;
      setDisabled(!disabled);
      return item;
    });
  },[]);
  React.useEffect(() => {},[disabled]);
  return (
    <>
      {alertModalState.uid && (
        <AlertModal
          text={`¿Deseas eliminar ${alertModalState.username}?`}
          functionModal={deleteCalificadora}
          setState={setAlertModalState}
        />
      )}
      <div className="rounded border w-[450px] mr-6">
        <h4 className="bg-[#4aceab] p-2">{title}</h4>
        <div className="flex flex-col items-center h-[350px] overflow-auto">
          {loadingCalificadora ? (
              <Loading />
          ) : data.length > 0 ? (
            data.map((item, idx) => {
              return (
                <div key={idx}
                className={`border w-full mb-2 flex items-center h-[98px] py-2 px-4 ${!item.disabled?"border-[#4aceab]":item.disabled&&"bg-[#f7f7f7]"}`}>
                  <input
                    type="text"
                    name="nombre"
                    disabled={item.disabled}
                    onChange={(e) => editParametro(e, idx)}
                    className="focus-visible:outline-none focus-visible:border-b-2 border-[#4aceab] mr-2 text-center bg-transparent w-full px-6"
                    placeholder={placeholder}
                    value={item.nombre}
                  />
                 {adminData.rol===0||adminData.rol===1?<div>
                    <div className="border w-10 h-10 flex justify-center">
                      <ButtonEdit onClick={() => handleClickEdit(idx)} />
                    </div>
                    <div className="border w-10 h-10 flex justify-center">
                      <ButttonDelete
                        onClick={() =>buttonDeleteClick(item.uid,item.nombre,idx)}
                      />
                    </div>
                  </div>:null}
                </div>
              );
            })
          ) : (
              <p className="text-center mt-10 animate-pulse">
                No Hay datos para mostrar
              </p>
          )}
        </div>
        {adminData.rol===0||adminData.rol===1?<div className="flex p-2 justify-center">
          <BtnGreen onClick={onClick} text={`Añadir ${textBtnAdd}`} />
          <BtnGreen onClick={() => updateCalificadora()} text="Guardar" />
        </div>:null}
      </div>
    </>
  );
}
