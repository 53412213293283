import React from 'react'
import BtnGreen from "../buttons/BtnGreen"
import BtnRed from "../buttons/BtnRed"

export default function DocumentsModal({setDocumentModalState}) {
    return (
        <div className='bg-black w-full h-full fixed flex items-center top-0 z-50 bg-opacity-30 left-0'>
            <div className='w-[800px] bg-white border drop-shadow-sm mx-auto h-[500px] my-auto p-6'>
                <button onClick={()=>setDocumentModalState(false)} className='float-right text-3xl -mt-2'>X</button>
                <p className='text-2xl text-center mb-4'>Documentos</p>
                <div className=' w-full h-14 border py-1 px-3 mb-1 flex justify-between'>
                    <span className='flex my-auto items-center'><img src="/imgs/usuario.png" alt="" className='h-[32px] w-[32px]' /><p className='ml-2'>comprbante-de-pago-de-prueba.pdf</p></span>
                    <div className='my-auto h-full flex items-center'>
                    <button className="bg-[#4aceab] text-white rounded w-24 mx-1 py-1 hover:bg-[#399e83] transition duration-200">Aceptar</button>
                        <button className='bg-yellow-500 transition duration-200 mx-1 p-1 w-24 rounded hover:bg-yellow-600 text-white'>Rechazar</button>
                        <button className="bg-red-600 text-white rounded w-24 mx-1 py-1 hover:bg-red-700 transition duration-200">Eliminar</button>
                    </div>
                </div>
                <div className=' w-full h-14 border py-1 px-3 mb-1 flex justify-between'>
                    <span className='flex my-auto items-center'><img src="/imgs/usuario.png" alt="" className='h-[32px] w-[32px]' /><p className='ml-2'>comprbante-de-pago-de-prueba.pdf</p></span>
                    <div className='my-auto h-full flex items-center'>
                    <button className="bg-[#4aceab] text-white rounded w-24 mx-1 py-1 hover:bg-[#399e83] transition duration-200">Aceptar</button>
                        <button className='bg-yellow-500 transition duration-200 mx-1 p-1 w-24 rounded hover:bg-yellow-600 text-white'>Rechazar</button>
                        <button className="bg-red-600 text-white rounded w-24 mx-1 py-1 hover:bg-red-700 transition duration-200">Eliminar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
