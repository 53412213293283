import React, { useState } from "react";
import { useCtx } from "../../../contexts/data.context";
import axios from "axios";
import { urlApi } from "../../../variablesGlobales";
import AlertModal from "../../modals/AlertModal";
import Loading from "../Loading";
import BtnDelete from "../../buttons/BtnDelete";

export default function Acciones({setLoading,rol,loading,emisores,setPageSavedState,pageSavedState}) {
  // STATES
  const [update, setUpdate] = useState(false);
  const [alertModalState, setAlertModalState] = useState({
    uid: "",
    stateConfirm: "",
    username: "",
  });
  const { rvAccionesData, setRvAccionesData,savedState } = useCtx();
  // EDIT FIELDS
  const editar = (e, id) => {
    rvAccionesData.map((item, idx) => {
      if (idx == id) {
        item[e.target.name] = e.target.value;
        setUpdate(!update);
      }
      return item;
    });
  };
  //DELETE RENTA VARIBLE
  const deleteRentaVariable = () => {
    axios
      .post(
        `${urlApi}deleteRentaVar`,
        { uid: alertModalState.uid },
        {
          headers: {
            "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
          },
        }
      )
      .then((response) => {
        setPageSavedState(!pageSavedState);
        console.log(response.data);
        setAlertModalState({
          uid: "",
          stateConfirm: "",
          username: "",
        });
      })
      .catch((error) => {
        console.log(error.request.responseText);
      });
  };
  //GET ACCIONES RENTA VARIABLE
  const getAcciones = () => {
    setLoading(true)
    axios
      .post(
        `${urlApi}RentaVarByTitle`,
        {
          titulo: "ACCIONES",
        },
        {
          headers: {
            "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
          },
        }
      )
      .then((response) => {
        console.log(response.data.data);
        setRvAccionesData(response.data.data);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    getAcciones();
    return () => {
      setRvAccionesData([]);
    };
  }, [pageSavedState]);
  React.useEffect(() => {}, [update]);
  return (
    <>
      {alertModalState.uid && (
        <AlertModal
          text={`¿Deseas eliminar ${alertModalState.username}?`}
          functionModal={deleteRentaVariable}
          setState={setAlertModalState}
        />
      )}
      <div>
        <table className="w-full mt-2">
          <thead>
            <tr className="font-semibold">
              <td>Titulo</td>
              <td>Emisor</td>
              <td>Valor nominal</td>
              <td>Numero de acciones</td>
              <td>Precio</td>
              <td>Tipo de mercado</td>
              {rol===0||rol===1?<td>Actions</td>:null}
            </tr>
          </thead>
          <tbody>
            {loading?<div className="absolute w-[80vw]"><Loading/></div>:
            rvAccionesData.length > 0 ? (
              rvAccionesData.map((item, idx) => {
                return (
                  <tr key={item.uid} className="border-b-2 border-[#398c9b]">
                    <td>
                      <p className=" border">{item.titulo}</p>
                    </td>
                    <td>
                      <select
                        name="emisor"
                        id="emisor"
                        onChange={(e) => editar(e, idx)}
                        className="focus-visible:outline-none bg-transparent border bg-white"
                        value={item.emisor}
                      >
                        <option value="">Seleccionar</option>
                        {emisores.map((emisor,idx) => {
                          return (
                            <option key={idx} value={emisor.nombre}>
                              {emisor.nombre}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                    <td>
                      <input
                        onChange={(e) => editar(e, idx)}
                        type="number"
                        value={item.valorNominal}
                        name="valorNominal"
                        className="focus-visible:outline-none w-full border text-center px-1"
                      />
                    </td>
                    <td>
                      <input
                        onChange={(e) => editar(e, idx)}
                        type="number"
                        value={item.numeroAcciones}
                        name="numeroAcciones"
                        className="focus-visible:outline-none w-full border text-center px-1"
                      />
                    </td>
                    <td>
                      {
                        <input
                          onChange={(e) => editar(e, idx)}
                          type="number"
                          value={item.precio}
                          name="precio"
                          className="focus-visible:outline-none w-full border text-center px-1"
                        />
                      }
                    </td>
                    <td>
                      <select
                        name="tipoMercado"
                        id="tipoMercado"
                        className="focus-visible:outline-none bg-transparent border bg-white"
                        value={item.tipoMercado}
                        onChange={(e) => editar(e, idx)}
                      >
                        <option value="Primario">Primario</option>
                        <option value="Secundario">Secundario</option>
                      </select>
                    </td>
                   {rol===0||rol===1? <td>
                      <BtnDelete
                        onClick={() =>
                          setAlertModalState({
                            uid: item.uid,
                            stateConfirm: "",
                            username: item.emisor,
                          })
                        }
                      />
                    </td>:null}
                  </tr>
                );
              })
            ) : (
              <div className="absolute w-[80vw]">
                <p className="text-center mt-10 animate-pulse">No hay datos para mostrar</p>
              </div>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
