import React, { useState } from "react";
import { useCtx } from "../../../contexts/data.context";
import { urlApi } from "../../../variablesGlobales";
import axios from "axios";
import AlertModal from "../../modals/AlertModal";
import Loading from "../Loading";
import BtnDelete from "../../buttons/BtnDelete";

  export default function PapelComercial({pageSavedState,rol,emisores,setPageSavedState,loading,setLoading,calificadoras}) {
  // STATES
  const [update, setUpdate] = useState(false);
  const [alertModalState, setAlertModalState] = useState({
    uid:"",
    stateConfirm:"",
    username:""
  });
  // DATA CONTEXT
  const { papelComercialData,setPapelComercialData} = useCtx();
  // EDIT ROL ON CONTECT DATA
  const editar = (e, id) => {
    papelComercialData.map((item,idx) => {
      if (idx==id ) {
        item[e.target.name] = e.target.value;
        console.log(item)
        setUpdate(!update);
      }
      return item;
    });
  };
            //DELETE RENTA FIJA PAPEL COMERCIAL
            const deleteRentaFijaPapelComercial = () => {
              axios
              .post(`${urlApi}deleteRentaFija`,{uid:alertModalState.uid},{
                headers: {
                  "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
                }
              })
              .then((response) => {
               
                console.log(response.data);
                setAlertModalState({
                  uid:"",
                  stateConfirm:"",
                  username:""
                })
                setPageSavedState(!pageSavedState)
                
              })
              .catch((error) => {
                console.log(error);
              });
          };

  //GET PAPEL COMERCIAL RENTA FIJA
  const getPapelComercial = () => {
    setLoading(true)
    axios
      .post(`${urlApi}RentaFijaByTitle`,{
        titulo:"PAPEL COMERCIAL"
      },
      {
        headers: {
          "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
        }
      })
      .then((response) => {
        console.log(response.data.data);
        setPapelComercialData(response.data.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    getPapelComercial()
    return ()=>{
      setPapelComercialData([])
    }
  },[pageSavedState]);
  React.useEffect(() => {},[update]);
  return (
    <>
    {
      alertModalState.uid&&<AlertModal text={`¿Deseas eliminar ${alertModalState.username}?`} functionModal={deleteRentaFijaPapelComercial} setState={setAlertModalState} />
    }
    <div className="w-full h-full">
      <table>
        <thead className="sticky z-50 top-0">
          <tr className="font-semibold bg-white">
            <td >
              <p className="w-36 ">Titulo</p>
            </td>
            <td>
              <p className="w-36">Numero de emision</p>
            </td>
            <td>
              <p className="w-24">Emisor</p>
            </td>
            <td>
              <p className="w-32">Monto emitido</p>
            </td>
            <td>
              <p className="w-32">Monto disponible</p>
            </td>
            <td>
              <p className="w-16">Cupon %</p>
            </td>
            <td>
              <p className="w-28">Tipo mercado</p>
            </td>
            <td>
              <p className="w-28">Plazo (Dias)</p>
            </td>
            <td>
              <p className="w-36">Fecha de emision</p>
            </td>
            <td>
              <p className="w-36">Fecha vencimiento</p>
            </td>
            <td>
              <p className="w-36">Pago de capital</p>
            </td>
            <td>
              <p className="w-24">Calificacion</p>
            </td>
            <td>
              <p className="w-36">Calificadora</p>
            </td>
            <td>
              <p className="w-32">Rendimiento %</p>
            </td>
            <td>
              <p className="w-32">Comision casa %</p>
            </td>
            <td>
              <p className="w-32">Comision bolsa %</p>
            </td>
            <td>
              <p className="w-36">Comision casa MS</p>
            </td>
            <td>
              <p className="w-36">Comision bolsa MS</p>
            </td>
            {rol===0||rol===1?<td>
              <p className="w-16">Actions</p>
            </td>:null}
          </tr>
        </thead>
        <tbody className="relative">
          {loading?<div className="absolute w-[80vw]"><Loading/></div>:
          papelComercialData.length >0 ?
          papelComercialData.map((pComercial,idx) => {
            return (
              <tr key={pComercial.uid} className="border-b-2 border-[#398c9b]">
                <td >
                  <p className="w-36 border">{pComercial.titulo}</p>
                </td>
                <td>
                  <input
                    type="text"
                    name="numeroEmision"
                    value={pComercial.numeroEmision}
                    onChange={(e) => editar(e,idx)}
                    className="focus-visible:outline-none w-36 border text-center  focus-visible:border-neutral-200 px-1"
                  />
                </td>
                <td>
                  <select
                    name="emisor"
                    id="emisor"
                    className="focus-visible:outline-none border w-24 bg-white"
                    onChange={(e) => editar(e, idx)}
                    value={pComercial.emisor}
                  >
                    <option value={""}>Seleccionar</option>
                    {emisores.map((emisor,idx) => {
                      return <option key={idx} value={emisor.nombre}>{emisor.nombre}</option>;
                    })}
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    name="montoEmitido"
                    onChange={(e) => editar(e, idx)}
                    value={pComercial.montoEmitido}
                    className="focus-visible:outline-none border text-center w-32  focus-visible:border-neutral-200 pl-1"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="montoDisponible"
                    onChange={(e) => editar(e, idx)}
                    value={pComercial.montoDisponible}
                    className="focus-visible:outline-none border text-center w-32  focus-visible:border-neutral-200 pl-1"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="cupon"
                    onChange={(e) => editar(e, idx)}
                    value={pComercial.cupon}
                    className="focus-visible:outline-none border text-center w-16  focus-visible:border-neutral-200 pl-1"
                  />
                </td>
                <td>
                  <select
                    name="tipoMercado"
                    className="focus-visible:outline-none border w-28 bg-white"
                    value={pComercial.tipoMercado}
                    onChange={(e) => editar(e, idx)}
                  >
                    <option value={"Primario"}>Primario</option>
                    <option value={"Secundario"}>Secundario</option>
                  </select>
                </td>
                <td>
                  <input
                    name="plazoDias"
                    type="number"
                    onChange={(e) => editar(e, idx)}
                    value={pComercial.plazoDias}
                    className="focus-visible:outline-none border text-center w-28  focus-visible:border-neutral-200 px-1"
                  />
                </td>
                <td>
                  <input
                    disabled={
                      pComercial.tipoMercado == "Primario" ? true : false
                    }
                    type={
                      pComercial.tipoMercado == "Primario" ? "text" : "date"
                    }
                    onChange={(e) => editar(e, idx)}
                    name="fechaEmision"
                    value={
                      pComercial.tipoMercado == "Primario"
                        ? ""
                        :pComercial.fechaEmision?pComercial.fechaEmision.slice(0,10):""
                    }
                    className="focus-visible:outline-none border text-center w-40  focus-visible:border-neutral-200 px-1"
                  />
                </td>
                <td>
                  <input
                    disabled={
                      pComercial.tipoMercado == "Primario" ? true : false
                    }
                    type={
                      pComercial.tipoMercado == "Primario" ? "text" : "date"
                    }
                    onChange={(e) => editar(e, idx)}
                    name="fechaVencimiento"
                    value={
                      pComercial.tipoMercado == "Primario"
                        ?""
                        :pComercial.fechaVencimiento?pComercial.fechaVencimiento.slice(0,10):""
                    }
                    className="focus-visible:outline-none border text-center w-40  focus-visible:border-neutral-200 px-1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="pagoCapital"
                    onChange={(e) => editar(e, idx)}
                    value={pComercial.pagoCapital}
                    className="focus-visible:outline-none border text-center w-36  focus-visible:border-neutral-200 px-1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="calificacion"
                    value={pComercial.calificacion}
                    onChange={(e) => editar(e, idx)}
                    className="focus-visible:outline-none border text-center w-24  focus-visible:border-neutral-200 px-1"
                  />
                </td>
                <td>
                  <select
                    name="calificadora"
                    className="focus-visible:outline-none border w-36 bg-white"
                    value={pComercial.calificadora}
                    onChange={(e) => editar(e, idx)}
                  >
                    {calificadoras.map((calificadora) => {
                      return (
                        <option value={calificadora.nombre}>{calificadora.nombre}</option>
                      );
                    })}
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    name="rendimiento"
                    value={pComercial.rendimiento}
                    onChange={(e) => editar(e, idx)}
                    className="focus-visible:outline-none border text-center w-full  focus-visible:border-neutral-200 px-1"
                  />
                </td>
                <td>
                  <input
                    disabled={
                      pComercial.tipoMercado == "Secundario" ? true : false
                    }
                    type="number"
                    name="comisionCasa"
                    value={
                      pComercial.tipoMercado == "Secundario"
                        ? ""
                        :pComercial.comisionCasa
                    }
                    onChange={(e) => editar(e, idx)}
                    className="focus-visible:outline-none border text-center w-full  focus-visible:border-neutral-200 px-1"
                  />
                </td>
                <td>
                  <input
                    disabled={
                      pComercial.tipoMercado == "Secundario" ? true : false
                    }
                    type="number"
                    name="comisionBolsa"
                    value={
                      pComercial.tipoMercado == "Secundario"
                        ? ""
                        :pComercial.comisionBolsa
                    }
                    onChange={(e) => editar(e, idx)}
                    className="focus-visible:outline-none border text-center w-full  focus-visible:border-neutral-200 px-1"
                  />
                </td>
                <td>
                <select
                    name="comisionCasaMS"
                    disabled={
                      pComercial.tipoMercado == "Primario" ? true : false
                    }
                    className={`focus-visible:outline-none border w-36 text-center ${ pComercial.tipoMercado == "Primario" ? "bg-[#fafafa]" : "bg-white"}`}
                    value={pComercial.tipoMercado=="Secundario"?pComercial.comisionCasaMS:""}
                    onChange={(e) => editar(e, idx)}
                  >
                      <option value="">{pComercial.tipoMercado==="Secundario"?"Seleccionar":""}</option>
                        <option value={4.00}> $4,00 </option>
                        <option value={9.00}> $9,00 </option>
                        <option value={18.00}> $18,00 </option>
                        <option value={35.00}> $35,00 </option>
                  </select>
                </td>
                <td>
                <select
                 disabled={
                  pComercial.tipoMercado == "Primario" ? true : false
                }
                    name="comisionBolsaMS"
                    className={`focus-visible:outline-none border w-36 text-center ${ pComercial.tipoMercado == "Primario" ? "bg-[#fafafa]" : "bg-white"}`}
                    value={pComercial.tipoMercado=="Secundario"?pComercial.comisionBolsaMS:""}
                    onChange={(e) => editar(e, idx)}
                  >
                     <option value="">{pComercial.tipoMercado==="Secundario"?"Seleccionar":""}</option>
                       <option value={4.00}> $4,00 </option>
                        <option value={9.00}> $9,00 </option>
                        <option value={18.00}> $18,00 </option>
                        <option value={35.00}> $35,00 </option>
                  </select>
                 
                </td>
               {rol===0||rol===1? <td>
                  <BtnDelete onClick={()=>setAlertModalState({
                    uid:pComercial.uid,
                    stateConfirm:"",
                    username:pComercial.numeroEmision
                  })} />
                </td>:null}
              </tr>
            );
          }):<div className="absolute w-[80vw]"><p className="text-center mt-10 animate-pulse">No hay datos para mostrar</p></div> }
        </tbody>
      </table>
    </div>
    </>
  );
}
