import React, { useState } from 'react'
import BtnGreen from '../buttons/BtnGreen'
import BtnRed from '../buttons/BtnRed'
import Loading from '../views/Loading'

export default function AlertModal({text,setState,functionModal}) {
  const [loadingState,setLoadingState]=useState(false)
    return (
        <div className='bg-black/20 drop-shadow fixed w-screen h-screen z-[9999] items-center flex justify-center top-0 left-0'>
          <div className='bg-white w-96 p-3 text-center rounded'>
          <p className='mb-3 text-xl'>{text}</p>
          {
            loadingState&&<Loading/>
          }
            <BtnRed text="Eliminar" onClick={()=>{functionModal();setLoadingState(true)}}/>
            <BtnGreen text="Cancelar" onClick={()=>setState({uid:"",stateConfirm:false,username:""})}/>
          </div>
        </div>
    )
}
