import React from "react";
import BtnGreen from "./buttons/BtnGreen";
import axios from "axios";
import { urlApi } from "../variablesGlobales";
import Loading from "./views/Loading";
import BtnDelete from "./buttons/BtnDelete";
import ButtonEdit from "./buttons/ButtonEdit";
import AlertModal from "./modals/AlertModal";
import { useCtx } from "../contexts/data.context";

export default function CardParametrosWithImg({
  title,
  textBtnAdd,
  data,
  onClick,
  setUpdate,
  update,
  placeholder,
  loadingEmisores,
  setLoadingEmisores,
  setEmisoresSaved,
  emisoresSaved
}) {
  const [adminData, setAdminData] = React.useState({});
  const [updateComponet, setUpdateComponet] = React.useState(false);
  const [alertModalState, setAlertModalState] = React.useState({
    uid:"",
    stateConfirm:"",
    username:""
  });
  // DATA CONTEXT
  const {setSavedState}=useCtx()
  // UPDATE EMISORES
      const updateEmisores=()=>{
        let count1=0
        let count2=0
        data.map((item,idx)=>{
         if(item.id){
          setLoadingEmisores(true)
           count1++
         const emisoresData=new FormData()
         emisoresData.append("uid",item.uid);
         emisoresData.append("nombre",item.nombre);
         emisoresData.append("image",item.path);
         axios.post(`${urlApi}saveDataEmisor`,emisoresData,
         {
          headers: {
            "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
          }
        })
         .then((response)=>{
           console.log(response)
           count2++
            if(count1==count2){
            setEmisoresSaved(!emisoresSaved)
            setSavedState({ open: true, successful: true });
            console.log("reload")
          } 
          })
         .catch((error)=>{
           console.log(error.response)
         })
         }
        })
      }
  // DELETE CALIFICADORA
  const buttonDeleteClick=(uid,nombre,idx)=>{
    uid?setAlertModalState({
      uid:uid,
      stateConfirm: "",
      username:nombre,
    }):data.splice(idx,1);setUpdate(!update);
  }
        // DELETE CALIFICADORA
        const deleteEmisor=()=>{
          axios.post(`${urlApi}deleteEmisor`,
          {
            id:alertModalState.uid
          },
          {
            headers: {
              "auth-token": localStorage.getItem("accessKey"), //the token is a variable which holds the token
            }
          }
          )
          .then((response)=>{
            console.log(response)
            setAlertModalState({
              uid:"",
              stateConfirm:"",
              username:""
            })
            setEmisoresSaved(!emisoresSaved)
          })
          .catch((error)=>{
            console.log(error.response)
          })
        }
  // EDIT FIELDS
  const editParametro = (e, idx) => {
    data[idx][e.target.name] = e.target.value;
    if(!data[idx]["id"]){data[idx]["id"]=numeroAleatorioDecimales();}
    setUpdate(!update);
    console.log(data);
  };
    // EDIT BUTTON HANDLER
    const handleClickEdit = (idx) => {
      data[idx]["disabled"] = !data[idx]["disabled"];
      setUpdateComponet(!updateComponet);
    };
  // CLICK UPLOAD IMAGEN
  const handleUploadImg = (idx) => {
    document.getElementById(idx).click();
  };
  // CLICK ADD EMISOR
  const addEmisor=()=>{
    onClick();
  }
  // HANDLE ON CHANGE IMG
  const handleOnChangeImg = (e,id) => {
    console.log(e.target.value);
    data[id][e.target.name]=e.target.files[0];
    // The button where the user chooses the local image to display
    const file = e.target.files[0];
    console.log(e.target.files[0])
    // FileReader instance
    var reader = new FileReader();
    // When the image is loaded we will set it as source of
    // our img tag
    reader.onloadend = function () {
      data[id]["falsePath"]=reader.result
      setUpdateComponet(!updateComponet);
      console.log(data)
    };
    if (file) {
      // Load image as a base64 encoded URI
      reader.readAsDataURL(file);
    }  
  };
  const numeroAleatorioDecimales=(min=0,max=10000)=>{
    var num = Math.random() * (max - min);
    return num + min;
    }
  React.useEffect(() => {
    setAdminData(JSON.parse(localStorage.getItem("adminData")))
  }, []);
  React.useEffect(() => {}, [updateComponet]);
  return (
    <>
    {
      alertModalState.uid&&<AlertModal text={`¿Deseas eliminar ${alertModalState.username}?`} functionModal={deleteEmisor} setState={setAlertModalState} />
    }
    <div className="rounded border w-[450px] mr-6">
      <h4 className="bg-[#4aceab] p-2">{title}</h4>
      <div id="emisorescontainer" className="flex flex-col items-center h-[350px] overflow-auto">
        {loadingEmisores?<Loading/>:
        data.length>0?
        data.reverse().map((item, idx) => {
          return (
            <div className={`border w-full mb-2 flex items-center py-2 px-4 ${!item.disabled?"border-[#4aceab]":item.disabled&&"bg-[#f7f7f7]"}`} >
              <img
                onClick={() => handleUploadImg(idx)}
                name="path"
                src={item.falsePath?item.falsePath:item.path?item.path:"/imgs/photo-camera.png"}
                id={`imagen${item.id}`}
                alt="profile picture"
                className="rounded-full h-20 w-20 object-cover shadow cursor-pointer hover:scale-95 border transition duration-200"
              />
              <input
                type="file"
                id={idx}
                name="path"
                disabled={item.disabled}
                onChange={(e) => handleOnChangeImg(e,idx)}
                hidden
              />
              <input
                type="text"
                name="nombre"
                onChange={(e) => editParametro(e, idx)}
                className="focus-visible:outline-none focus-visible:border-b-2 border-[#4aceab] bg-transparent w-full h-10 mr-2 ml-2 px-4"
                placeholder={placeholder}
                value={item.nombre}
                disabled={item.disabled}
              />
              {adminData.rol===0||adminData.rol===1?<div className="-mr-2">
                <div className="border w-10 h-10 flex justify-center"> <ButtonEdit onClick={()=>handleClickEdit(idx)} /> </div>
                <div className="border w-10 h-10 flex justify-center"> <BtnDelete onClick={()=>buttonDeleteClick(item.uid,item.nombre,idx)} /> </div>
              </div>:null}
            </div>
          );
        }):<p className="text-center mt-10 animate-pulse">No Hay datos para mostrar</p>}
      </div>
      {adminData.rol===0||adminData.rol===1?<div className="flex p-2 justify-center">
        <BtnGreen onClick={()=>addEmisor()} text={`Añadir ${textBtnAdd}`} />
        <BtnGreen onClick={()=>updateEmisores()} text="Guardar"/>
      </div>:null}
    </div>
    </>
  );
}
